import TratamientosMedia from "../components/tratamientos-media";
import TratamientoPotada from "../components/tratamientos-portada";
import { useEffect } from "react";
const Tratamientos = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TratamientoPotada />
      <TratamientosMedia />
    </>
  );
};

export default Tratamientos;
