import { Carousel } from "react-bootstrap";

const InicioTestimonios = () => {
  return (
    <>
      <div className="uk-margin-xlarge-bottom">
        <div class="uk-padding-large uk-margin-left">
          <div>
            <h1 class=" uk-text-center">
              <span style={{ color: "#ff6912" }}>
                {" "}
                Testimonios de nuestros pacientes{" "}
              </span>
            </h1>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-lead uk-text-medium"
              style={{ color: "#173864", fontWeight: 500 }}
            >
              Para nosotros es importante saber lo que nuestros pacientes dicen
              de nosotros y de nuestros servicios.
            </span>
          </div>
        </div>
        <div className="uk-flex uk-flex-center ">
          <Carousel className="uk-width-2-3 uk-text-center">
            <Carousel.Item>
              <div
                class="uk-card uk-card-default uk-card-body uk-card-hover uk-text-warning "
                style={{ backgroundColor: "#173864" }}
              >
                <div>
                  Cuando la mejor atención y el pleno conocimiento de la materia
                  se juntan... Excelente médico en todos los aspectos.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                class="uk-card uk-card-default uk-card-body uk-card-hover uk-text-warning"
                style={{ backgroundColor: "#173864" }}
              >
                <div>
                  {" "}
                  <span className="uk-text-warning ">
                    Es un excelente medíco y muy preparado. Exelente atención,
                    100% recomendable
                  </span>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                class="uk-card uk-card-default uk-card-body uk-card-hover uk-text-warning"
                style={{ backgroundColor: "#173864" }}
              >
                <div>
                  Excelente doctor, muy profesional, un gran ejemplo a seguir,
                  mi más grande admiración sensei. Felicidades
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default InicioTestimonios;
