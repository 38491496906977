import React from "react";
import Plantilla_1 from "../images/Plantillas_1.png";
import Plantilla_2 from "../images/Plantillas_2.png";
import Plantilla_3 from "../images/Plantillas_3.png";
import Plantilla_4 from "../images/Plantillas_4.png";
import Plantilla_5 from "../images/Plantillas_5.png";
import Plantilla_6 from "../images/Plantillas_6.png";
import Plantilla_7 from "../images/Plantillas_7.png";
import Plantilla_8 from "../images/Plantillas_8.png";
import { useNavigate } from "react-router-dom";

const Productos = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Plantillas = [
    {
      id: 1,
      name: "EVA SPORT",
      img: Plantilla_1,
      rotate: -20,
    },
    {
      id: 2,
      name: "ULTRA SUAVE",
      img: Plantilla_2,
      rotate: -20,
    },
    {
      id: 3,
      name: "KIDS",
      img: Plantilla_3,
      rotate: -20,
    },
    {
      id: 4,
      name: "ELITE",
      img: Plantilla_4,
      rotate: -20,
    },
    {
      id: 5,
      name: "CONFORT",
      img: Plantilla_5,
      rotate: -20,
    },
    {
      id: 6,
      name: "ORTOPEDICA",
      img: Plantilla_6,
      rotate: -30,
    },
    {
      id: 7,
      name: "STANDARD",
      img: Plantilla_7,
      rotate: -20,
    },
    {
      id: 8,
      name: "BIO-MAGNÉTICA",
      img: Plantilla_8,
      rotate: 50,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <div className="uk-visible@m">
        {" "}
        <div className="uk-padding ">
          <div>
            <h1 className=" uk-text-center uk-margin-large-bottom">
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                No olvides conocer nuestras plantillas ortopedicas!
              </span>
            </h1>
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <div
            className=" uk-flex-center uk-text-center uk-width-1-2"
            uk-grid={"true"}
          >
            {Plantillas.map((Plantilla) => (
              <button
                key={Plantilla.id}
                onClick={() => navigate(`/plantillas/${Plantilla.id}`)}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <div
                  className="uk-inline"
                  style={{ boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.18)" }}
                >
                  <img
                    src={Plantilla.img}
                    alt={Plantilla.name}
                    style={{
                      width: 400,
                      height: 400,
                      transform: `rotate(${Plantilla.rotate}deg)`,
                    }}
                  />
                  <div class="uk-position-small uk-overlay uk-overlay-default ">
                    <p style={{ color: "#ff6912", fontSize: 24 }}>
                      {Plantilla.name}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="uk-hidden@m uk-margin-xlarge-top">
        {" "}
        <div className="uk-padding ">
          <div>
            <h1 className=" uk-text-center uk-margin-large-bottom">
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                No olvides conocer nuestras plantillas ortopedicas!
              </span>
            </h1>
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <div
            className=" uk-flex-center uk-text-center uk-width-1-1"
            uk-grid={"true"}
          >
            {Plantillas.map((Plantilla) => (
              <button
                key={Plantilla.id}
                onClick={() => navigate(`/plantillas/${Plantilla.id}`)}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <div key={Plantilla.id}>
                  <div
                    className="uk-inline uk-margin-bottom"
                    style={{ boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.18)" }}
                  >
                    <img
                      src={Plantilla.img}
                      alt={Plantilla.name}
                      style={{
                        width: 400,
                        height: 400,
                        transform: `rotate(${Plantilla.rotate}deg)`,
                      }}
                    />
                    <div class="uk-position-small uk-overlay uk-overlay-default ">
                      <p style={{ color: "#ff6912", fontSize: 24 }}>
                        {Plantilla.name}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Productos;
