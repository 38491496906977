import { useEffect, useState } from "react";
import Logo from "../images/logo-horizontal.png";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { IoCallOutline } from "react-icons/io5";

const Header = () => {
  const [pos, setPos] = useState("");

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 300) {
        setPos("moved");
      } else {
        setPos("top");
      }
    });
  }, []);

  return (
    <>
      <Button
        style={{
          border: "1px solid #000000",
          position: "absolute",
          left: "75%",
          top: "3%",
        }}
        className="boton-color uk-hidden@m  uk-hidden@m "
        uk-toggle="target: #sidenav"
      >
        <AiOutlineMenu size={20} />
      </Button>
      {pos === "moved" ? (
        <div
          className="uk-hidden@m  uk-navbar-transparent"
          data-uk-sticky="cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 1"
          uk-navbar
          style={{ backgroundColor: "#eee", display: "flex" }}
        >
          {" "}
          <NavLink to="/" class="uk-navbar-item uk-logo uk-navbar-right ">
            <img
              src={Logo}
              alt="logo"
              style={{
                objectFit: "contain",
                width: 100,
                height: 100,
                marginLeft: 20,
              }}
            />
          </NavLink>
          <div
            style={{
              display: "flex",
            }}
          >
            <IoCallOutline size={25} style={{ marginRight: 10 }} />
            <a
              href="tel:+527446010021"
              className="uk-link-muted uk-text-decoration-none"
              style={{ marginTop: 35 }}
            >
              {" "}
              <h4 class=" uk-text-center" style={{ color: "#ff6912" }}>
                744 601 0021
              </h4>
            </a>
          </div>
          <Button
            style={{
              backgroundColor: "#eee",
              border: "none",
              marginLeft: "20%",
              height: "100px",
            }}
            className="uk-navbar-toggle uk-hidden@m "
            uk-toggle="target: #sidenav"
          >
            <AiOutlineMenu size={30} color={"black"} />
          </Button>
        </div>
      ) : null}
      {/* Navbar para escritorio */}
      <div className="uk-visible@m">
        <nav
          className="uk-navbar-container uk-navbar-transparent"
          data-uk-sticky="animation: uk-animation-slide-top; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 200"
          uk-navbar
        >
          <div className="uk-navbar-right">
            <NavLink to="/" className="image-container">
              <img
                src={Logo}
                className="image-logo"
                alt="logo doctorstotalclinic"
              />
            </NavLink>

            <div
              className="uk-navbar-right uk-visible@m"
              style={{ marginRight: 300 }}
            >
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Inicio
              </NavLink>

              <NavLink
                to="/nosotros"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Nosotros
              </NavLink>

              <NavLink
                to="/tratamientos"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Tratamientos
              </NavLink>
              <NavLink
                to="/aseguradoras"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Aseguradoras
              </NavLink>
              <NavLink
                to="/plantillas"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Plantillas
              </NavLink>

              <NavLink
                to="/contacto"
                className={({ isActive }) => (isActive ? "active" : "nav_link")}
              >
                Contacto
              </NavLink>

              <a
                className=" uk-text-decoration-none uk-link-heading uk-text-default uk-text-emphasis "
                href="https://api.whatsapp.com/send/?phone=5217441884422&text=Buen+Dia+Me+Gustaria+Agendar+Una+Cita%3A&app_absent=0"
                style={{
                  backgroundColor: "rgb(255,105,18)",
                  borderRadius: "24px",
                  height: "30px",
                  width: "110px",
                }}
              >
                <span style={{ color: "white", marginLeft: 10, marginTop: 15 }}>
                  Agendar cita
                </span>
              </a>
              <div
                style={{
                  position: "absolute",
                  right: 300,
                  top: 0,
                  display: "flex",
                }}
              >
                <IoCallOutline size={25} style={{ marginRight: 10 }} />
                <a
                  href="tel:+527446010021"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  <h4 class=" uk-text-center" style={{ color: "#ff6912" }}>
                    744 601 0021
                  </h4>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="sidenav" uk-offcanvas="flip: true" className="uk-offcanvas">
        <nav className="uk-offcanvas-bar uk-flex uk-flex-column uk-text-center uk-text-large ">
          <span
            style={{
              color: "black",
              backgroundColor: "white",
              borderRadius: 20,
            }}
          >
            Menú
          </span>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/"
          >
            Inicio
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/nosotros"
          >
            Nosotros
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/tratamientos"
          >
            Tratamientos
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/aseguradoras"
          >
            Aseguradoras
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/plantillas"
          >
            Plantillas
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "uk-text-warning uk-text-decoration-none  uk-link-heading   uk-margin-top "
                : "uk-text-decoration-none  uk-link-heading   uk-margin-top "
            }
            to="/contacto"
          >
            Contacto
          </NavLink>

          <a
            className=" uk-link-heading uk-text-default uk-text-emphasis uk-margin-top uk-margin-xlarge-left "
            href="https://api.whatsapp.com/send/?phone=5217441884422&text=Buen+Dia+Me+Gustaria+Agendar+Una+Cita%3A&app_absent=0"
            style={{
              backgroundColor: "rgb(255,105,18)",
              borderRadius: "10px",
              height: "30px",
              width: "110px",
            }}
          >
            <span
              uk-icon="icon:file-edit "
              style={{ color: "white", marginLeft: 10 }}
            >
              Agendar cita
            </span>
          </a>
        </nav>
      </div>
    </>
  );
};

export default Header;
