import * as React from "react";
import Josafat from "../images/josafat.png";
import Indiv2 from "../images/ind2.png";
import Indiv4 from "../images/ind4.png";
import Indiv6 from "../images/ind6.png";
import Indiv9 from "../images/ind9.png";
import Equipo3 from "../images/equipo.jpg";
import Indiv8 from "../images/ind8.png";
import Indiv10 from "../images/ind10.png";

const NosotrosEquipo = () => {
  return (
    <>
      <div className="uk-padding-large">
        <img alt="" src={Equipo3} className="img-fluid" />
      </div>
      {/* Aqui termina la imagen */}

      {/* Comienza el Titulo */}
      <div class="uk-padding-large uk-margin-left">
        <div>
          <h1 class=" uk-text-center">
            <span style={{ color: "#ff6912" }}> Nuestra Familia</span>
          </h1>
        </div>
        <div className="uk-text-center">
          <span
            className="uk-text-light uk-text-small  "
            style={{ fontWeight: 500 }}
          >
            {" "}
            Conozca un poco a nuestra familia de Doctors Total Clinic{" "}
          </span>
        </div>
      </div>
      {/* Aqui termina el titulo */}

      <div className="uk-visible@m">
        <div className="uk-flex uk-flex-center uk-margin-large-right">
          <div className="uk-flex uk-flex-middle uk-text-center uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img alt="Dr. Jos Arroyo" src={Josafat} className="img-fluid" />{" "}
            </div>
            <div
              className="uk-text-left"
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title uk-text-warn">Dr. Jos</h3>
                <p>Médico cirujano Ortopedista y traumatólogo</p>
                <p className="uk-text-justify">
                  Dr. Jos Recibió su entrenamiento En uno de los más
                  prestigiosos centros de estudios de México, La Universidad
                  Naval, por la Secretaría de Marina. Además de adiestramientos
                  en el Instituto Nacional de Neurocirugía Por la Universidad de
                  la Habana en Cuba Miembro activo de la Federación Mexicana de
                  colegios ortopedia y traumatología.
                </p>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img
                alt="Dra. Afrania zamora Lizárraga"
                src={Indiv2}
                className="img-fluid"
              />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Dra. Frany</h3>
                <p>Médico Anestesiólogo</p>
                <p className="uk-text-justify">
                  Dra. Afrania zamora Lizárraga medico anestesiólogo, es
                  egresada de la UANL. Con diplomado en medicina del dolor y
                  cuidados paliativos. Certificada por Colegio llegues mexicano
                  de anestesiología. Universidad autónoma de Nuevo León( UANL).
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* primer grid */}

        <div className="uk-flex uk-flex-center uk-margin-large-right">
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img alt="Cristhel Ramos" src={Indiv6} className="img-fluid" />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Cristhel Ramos</h3>
                <p className="uk-text-justify">Licenciada en nutrición</p>
                <p className="uk-text-justify">
                  Licenciada en nutrición Cristhel ramos, estudios finalizados
                  en la universidad americana de Acapulco. Educadora en
                  diabetes, isak nivel 1, conocimientos en estética corporal y
                  deportivo, amplia experiencia en asesoramiento, control
                  nutricional y prescripción de programas nutricionales
                  adecuados a objetivos patológicos o no patológicos de cada
                  paciente para realizar cambios en sus hábitos alimenticios
                </p>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img alt="Tania Arellano" src={Indiv4} className="img-fluid" />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Tania Arellano</h3>
                <p className="uk-text-justify">Fisioterapeuta</p>
                <p className="uk-text-justify">
                  Se graduó de la universidad IPETH puebla en el 2017, comenzó a
                  trabajar con pacientes en el area de traumatología y
                  oncología. Actualmente se sigue preparando en el area de
                  oncología.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-large-right">
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img
                alt="Aaron Jimenez Valdéz"
                src={Indiv8}
                className="img-fluid"
              />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Dr. Aaron Jimenez Valdéz </h3>
                <p className="uk-text-justify">Ginecologo</p>
                <p className="uk-text-justify">
                  El Dr. Aaron Jimenez Valdéz, es un Médico Naval especialista
                  en Ginecología y Obstetricia, egresado del Centro 21 Médico
                  Naval, certificado por el Consejo Mexicano de Ginecología y
                  Obstetricia (CMGO); Experto en la fisiología femenina y
                  Embarazo de alto riesgo.
                </p>
              </div>
            </div>
          </div>
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img
                alt="Dra. Annel Ortiz Vilorio"
                src={Indiv9}
                className="img-fluid"
              />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Dra. Annel Ortiz Vilorio</h3>
                <p className="uk-text-justify">
                  Médico Cirujano naval especialista en nefrología
                </p>
                <p className="uk-text-justify">
                  Especialista en la salud renal. Médico naval especialista en
                  nefrología por el Centro Medico Nacional siglo XXI. Miembro
                  del consejo mexicano de nefrología y Instituto Mexicano de
                  Investigaciones Nefrológicas. Experta en atención de
                  enfermedades del riñón, diálisis peritoneal, hemodiálisis,
                  quistes renales,infecciones de vías urinarias. Especialista en
                  control Óptimo de hipertensión y diabetes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-large-right">
          <div className="uk-flex uk-flex-middle uk-text-center  uk-width-1-2">
            <div
              className="uk-flex uk-card-body uk-widh"
              uk-scrollspy="cls:uk-animation-fade"
            >
              <img
                alt="Liz Anel Gálvez Cuenca"
                src={Indiv10}
                className="img-fluid"
                style={{ width: "600px" }}
              />
            </div>
            <div
              className="  uk-text-left  "
              uk-scrollspy="cls:uk-animation-slide-right"
            >
              <div className=" uk-container-xsmall uk-text-break ">
                <h3 class="uk-card-title">Dr. Liz Anel Gálvez Cuenca </h3>
                <p className="uk-text-justify">
                  Especialista en medicina del deporte
                </p>
                <p className="uk-text-justify">
                  La Dra. Liz Anel Gálvez Cuenca, Médico cirujano egresada de
                  universidad autónoma de Guerrero, especialista en medicina del
                  deporte por el instituto politécnico nacional.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Comienza la version movil */}

      <div className="uk-hidden@m">
        {/* Grid de josafat */}

        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img alt="" src={Josafat} className="img-fluid" />
            <h3 class="uk-card-title uk-text-warn">Dr. Jos Arroyo</h3>
            <p>Médico cirujano Ortopedista y traumatólogo</p>
            <p>
              Dr. Jos Recibió su entrenamiento En uno de los más prestigiosos
              centros de estudios de México, La Universidad Naval, por la
              Secretaría de Marina. Además de adiestramientos en el Instituto
              Nacional de Neurocirugía Por la Universidad de la Habana en Cuba
              Miembro activo de la Federación Mexicana de colegios ortopedia y
              traumatología.
            </p>
          </div>
        </div>

        <div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img
              alt="Dra. Afrania zamora Lizárraga"
              src={Indiv2}
              className="img-fluid"
            />
            <h3 class="uk-card-title">Dra. Afrania zamora Lizárraga</h3>
            <p>Médico Anestesiólogo</p>
            <p>
              Dra. Afrania zamora Lizárraga medico anestesiólogo, es egresada de
              la UANL. Con diplomado en medicina del dolor y cuidados
              paliativos. Certificada por Cg llegues mexicano de anestesiología.
              Universidad autónoma de Nuevo León( UANL).
            </p>
          </div>
        </div>
        {/* Termina grid de josfat */}

        {/* Comienza el grid */}

        {/* Termina el primer grid */}

        {/* Comienza segundo grid */}

        {/* Termina segundo grid */}

        {/* Comienza tercer grid */}

        <div div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img
              alt="Dra. Annel Ortiz Vilorio"
              src={Indiv9}
              className="img-fluid"
            />
            <h3 class="uk-card-title">Dra. Annel Ortiz Vilorio</h3>
            <p>Médico Cirujano naval especialista en nefrología</p>
            <p>
              Especialista en la salud renal. Médico naval especialista en
              nefrología por el Centro Medico Nacional siglo XXI. Miembro del
              consejo mexicano de nefrología y Instituto Mexicano de
              Investigaciones Nefrológicas. Experta en atención de enfermedades
              del riñón, diálisis peritoneal, hemodiálisis, quistes
              renales,infecciones de vías urinarias. Especialista en control
              Óptimo de hipertensión y diabetes.
            </p>
          </div>
        </div>

        {/* Comienza el cuarto grid */}

        <div div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img alt="Cristhel Ramos" src={Indiv6} className="img-fluid" />
            <h3 class="uk-card-title">Cristhel Ramos</h3>
            <p>Licenciada en nutrición</p>
            <p>
              Licenciada en nutrición Cristhel ramos, estudios finalizados en la
              universidad americana de Acapulco. Educadora en diabetes, isak
              nivel 1, conocimientos en estética corporal y deportivo, amplia
              experiencia en asesoramiento, control nutricional y prescripción
              de programas nutricionales adecuados a objetivos patológicos o no
              patológicos de cada paciente para realizar cambios en sus hábitos
              alimenticios
            </p>
          </div>
        </div>
        <div div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img alt="Tania Arellano" src={Indiv4} className="img-fluid" />
            <h3 class="uk-card-title">Tania Arellano </h3>
            <p>Fisioterapeuta</p>
            <p>
              Se graduó de la universidad IPETH puebla en el 2017, comenzó a
              trabajar con pacientes en el area de traumatología y oncología.
              Actualmente se sigue preparando en el area de oncología.
            </p>
          </div>
        </div>
        <div div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img
              alt="Aaron Jimenez Valdéz"
              src={Indiv8}
              className="img-fluid"
            />
            <h3 class="uk-card-title">Dr. Aaron Jimenez Valdéz</h3>
            <p>Ginecologo</p>
            <p>
              El Dr. Aaron Jimenez Valdéz, es un Médico Naval especialista en
              Ginecología y Obstetricia, egresado del Centro 21 Médico Naval,
              certificado por el Consejo Mexicano de Ginecología y Obstetricia
              (CMGO); Experto en la fisiología femenina y Embarazo de alto
              riesgo.
            </p>
          </div>
        </div>
        <div div className="uk-width-1-1">
          <div className="uk-card uk-card-default uk-card-body">
            <img
              alt="Aaron Jimenez Valdéz"
              src={Indiv10}
              className="img-fluid"
              style={{ height: "450px", marginBottom: "30px" }}
            />
            <h3 class="uk-card-title">Liz Anel Gálvez Cuenca</h3>
            <p>Especialista en medicina del deporte.</p>
            <p>
              La Dra. Liz Anel Gálvez Cuenca, Médico cirujano egresada de
              universidad autónoma de Guerrero, especialista en medicina del
              deporte por el instituto politécnico nacional.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NosotrosEquipo;
