import { useEffect } from "react";
import NosotrosNosotros from "../components/nosotros-nosotros";
import NosotrosEquipo from "../components/nosotros-equipo";

const Nosotros = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NosotrosNosotros />
      <NosotrosEquipo />
    </div>
  );
};

export default Nosotros;
