import IMG1 from "../images/1.png";
import IMG2 from "../images/2.png";
import IMG3 from "../images/3.png";
import IMG4 from "../images/4.png";
import IMG5 from "../images/5.png";
import IMG6 from "../images/6.png";
import IMG7 from "../images/7.png";
import IMG8 from "../images/8.png";
import IMG9 from "../images/9.png";
import IMG10 from "../images/10.png";
import IMG11 from "../images/11.png";
import IMG12 from "../images/12.png";
import IMG13 from "../images/13.png";
import IMG14 from "../images/14.png";

const TratamientosMedia = () => {
  const Images = [
    {
      id: 1,
      src: IMG1,
      alt: "Trtamiento para el dolor de rodilla sin cirugia",
      titulo_Tratamiento_1:
        "Ablación de nervios geniculados por radiofrecuencia.",
      texto: `Este procedimiento consiste en generar una descarga de energía de radiofrecuencia guiada por rayos X y ultrasonografía, la cual provocará la degradación térmica de las estructuras nerviosas sensitivas, ayudando a disminuir el dolor hasta en un 80% de manera inmediata sin necesidad de cirugía, sin cicatrices, sin hospitalización, y completamente ambulatorio. Este procedimiento de última tecnología fue diseñado especialmente para aquellos pacientes que no desean cirugía o que sus condiciones no lo permiten. `,
      titulo_Tratamiento_2: "Viscosuplementación",
      texto_2:
        "Es la aplicación intraarticular percutánea de una sustancia visco-elastica considerada una excelente alternativa terapéutica que tiene el objetivo de disminuir el dolor articular, y mejorar el estado funcional de la articulación artrósica. \nBeneficios: Segura, resultados inmediatos, bajo costo.",
      titulo_Tratamiento_3: "Terapia biológica",
      texto_3:
        "Es la aplicación de tejido autólogo, fuente de factores de crecimiento y células progenitoras, en el área de la lesión, con el fin de concebir la reparación y formación de nuevo tejido.",
    },
    {
      id: 2,
      src: IMG2,
      alt: "Trtamiento sin cirugia para el dolor de espalda",
      titulo_Tratamiento_1: "Bloqueo epidural con esteroides",
      texto:
        "Se trata de una aplicación de medicamentos para el control del dolor directamente en el espacio epidural de la columna vertebral, disminuyendo el dolor de manera drástica en las zonas afectadas, esto con la finalidad de proporcionar alivio de manera temporal o prolongada. Es un tratamiento realizado de manera ambulatoria, seguro y mínimamente invasivo.",
      titulo_Tratamiento_2:
        "Ablación sensitiva de las articulaciones facetarias",
      texto_2:
        "Este procedimiento consiste en generar una descarga de energía de radiofrecuencia guiada por rayos X y ultrasonografía a nivel de la faceta articular, ya sea en columna lumbar, dorsal o cervical afectada. El objetivo de este procedimiento es desactivar el nervio encargado de transmitir el dolor. Este procedimiento está indicado en aquellas personas que sufren de dolor constante en espalda baja o cuello, disminuyendo el dolor hasta en un 80% de manera inmediata sin necesidad de cirugía, sin cicatrices, sin hospitalización, y completamente ambulatorio.",
      titulo_Tratamiento_3: "Discolisis percutánea con radiofrecuencia",
      texto_3:
        "Ideal para pacientes que no son candidatos a la cirugía de columna debido a diversos factores. Consiste en la aplicación de radiofrecuencia acompañada de ozono para aliviar el dolor de espalda crónico causado por las hernias de los discos vertebrales, los cuales comprimen los nervios, causando dolor, entumecimiento y “hormigueo” de acuerdo al área que está siendo afectada. Es un procedimiento mínimamente invasivo, donde por medio de rayos X, el médico tratante llega con precisión a la zona afectada, no requiere internamiento.",
    },
    {
      id: 3,
      src: IMG3,
      alt: "Trtamiento para el dolor de cadera",
      titulo_Tratamiento_1: "Viscosuplementación",
      texto:
        "Es la aplicación intraarticular percutánea de una sustancia visco-elastica, considerada una excel alternativa terapéutica que tiene el objetivo de disminuir el dolor articular, y mejorar el estado funcional de la articulacion artrósica. Beneficios:Segura, resultados inmediatos, bajo costo.",
      titulo_Tratamiento_2: "Bloqueo PENG",
      texto_2:
        "Es un método excelente para el control del dolor en pacientes que cuentan con dolor crónico de cadera, ya sea por desgaste de la articulación, o bien, por fractura de la misma articulación. Consiste en el bloqueo del nervio de la rama sensitiva de manera directa, el cual se encarga de la transmisión de dolor, aminorando el dolor hasta en un 80%, así disminuyendo la ingesta de medicamentos que pueden ser dañinos a largo plazo. Es de mucha utilidad para pacientes que no desean ser intervenidos quirúrgicamente o que no son candidatos a esta debido a otras comorbilidades, ayuda a mejorar en gran medida la calidad de vida del paciente con riesgos mínimos y sin cirugía",
    },
    {
      id: 4,
      src: IMG4,
      alt: "Tratamiento para el dolor de hombro sin cirugia",
      titulo_Tratamiento_1: "Ablación de nervio supraescapular",
      texto:
        "Tratamiento guiado por medio de ultrasonido para localizar el nervio responsable de la transmisión del dolor al hombro y brazo, en donde por medio de radiofrecuencia se apaga dicho nervio. Ideal para pacientes con dolor crónico causado por patologías de hombro, tales como desgaste articular o lesión muscular del manguito rotador. Este procedimiento es una excelente alternativa para aquellos que sufren de dolor severo en hombro y que no tiene la posibilidad de operarse ya sea debido a su estado de salud, actividades laborales o miedo a la cirugía, el resultado es inmediato y mejora la vida del paciente de manera espectacular sin necesidad de más medicamentos ",
      titulo_Tratamiento_2: "Viscosuplementación",
      texto_2:
        "Es la aplicación intraarticular percutánea de una sustancia visco-elastica considerada una excelente alternativa terapéutica que tiene el objetivo de disminuir el dolor articular, y mejorar el estado funcional de la articulación artrósica. \nBeneficios: Segura, resultados inmediatos, bajo costo.",
    },
    {
      id: 5,
      src: IMG5,
      alt: "Protesis de rodilla",
      texto:
        "La artroplastia total de rodilla (Prótesis de rodilla) es probablemente la cirugía ortopédica con mayor tasa de éxitos y produce una gran satisfacción al paciente y al cirujano. Este procedimiento  ayuda de forma considerable a mejorar la función, eliminar el dolor y a proporcionar una mejor calidad de vida a los pacientes afectados de graves alteraciones degenerativas de la rodilla. Por todo ello, el número de prótesis de rodilla implantadas ha aumentado de forma notable. Nuestros cirujanos altamente calificados garantizan excelentes resultados. La artroplastia  de rodilla es una cirugía que te cambia la vida, no solamente te quita el dolor, también ayuda a recuperar la función y volver a hacer las actividades que tanto amas",
      titulo_Tratamiento_1: "Prótesis de rodilla",
    },
    {
      id: 6,
      src: IMG6,
      alt: "Protesis de cadera",
      texto:
        "Una prótesis de cadera es un dispositivo médico artificial que reemplaza a nuestra cadera natural, que normalmente está indicada en fracturas de cadera o desgaste de la articulación de la cadera Las prótesis de cadera se utilizan para aliviar el dolor y para devolver la función a pacientes que no responden bien al tratamiento conservador (reposo, rehabilitación física, medicamentos anti-inflamatorios, etc.)Es una cirugía que te ayuda a recuperarte de manera excelente, recuperando tu calidad de vida y ayudándote a volver a hacer las cosas que tanto amas",
      titulo_Tratamiento_1: "Prótesis de cadera",
    },
    {
      id: 7,
      src: IMG7,
      alt: "Protesis de disco cervical",
      texto:
        "Una prótesis de disco intervertebral consiste en la sustitución del disco intervertebral por un dispositivo que tiene la capacidad de mantener la movilidad entre dos vértebras. La cirugía de prótesis de disco consiste en una intervención quirúrgica donde se reemplaza el disco intervertebral con una prótesis. Con el fin de reducir las molestias y el dolor causado por el disco ya dañado. La principal ventaja de esta intervención es que permite la movilidad de la columna en casos en los que existe un desgaste severo del disco afectado. Una vez realizada la intervención, el paciente podrá practicar las actividades físicas y deportivas que hacía antes de que comenzasen las molestias",
      titulo_Tratamiento_1: "Prótesis de disco cervical",
    },
    {
      id: 8,
      src: IMG8,
      alt: "Protesis de disco lumbar",
      texto:
        "Una prótesis de disco lumbar, está diseñada para amortiguar y reemplazar el disco lumbar que se encuentre dañado. Diseñado para el desgaste de discos intervertebrales, o bien, aquellos que hayan resultado dañados. Se coloca por medio de cirugía, la cual es guiada por rayos X durante el procedimiento, para así verificar la correcta colocación de dicha prótesis. La principal ventaja es que, una vez recuperado el paciente, puede realizar las actividades que fueron abandonadas por las molestias de dicha condición.",
      titulo_Tratamiento_1: "Prótesis de disco lumbar",
    },
    {
      id: 9,
      src: IMG9,
      alt: "Artroscopia",
      texto:
        "Similar a una endoscopia, la artroscopia es un procedimiento quirúrgico mínimamente invasivo, donde posterior a la intervención, el paciente puede irse a casa. Consiste en la introducción de una pequeña cámara junto con el material quirúrgico para tener una vista a detalle de las zonas que se encuentran afectadas por una lesión de rodilla. Este procedimiento tiene como objetivo reparar las zonas afectadas en una lesión de manera directa. Su rápida recuperación y al ser mínimamente invasivo la vuelve un excelente procedimiento terapéutico",
      titulo_Tratamiento_1: "Artroscopia",
    },
    {
      id: 10,
      src: IMG10,
      alt: "Trtamientos para lesiones en deportistas",
      titulo_Tratamiento_1: "Descarga muscular",
      texto:
        "Se trata de una terapia manual intensa en la cual se aplican compresiones, fricciones y presiones en los músculos con la finalidad de producir un efecto analgésico. Se aplica en los músculos que tienen mayor tono muscular como causa de la carga de entrenamiento.",
      titulo_Tratamiento_2: "Rehabilitación",
      texto_2:
        "¿Qué te hacen en rehabilitación? La rehabilitación puede ayudar a muchas funciones corporales, incluso problemas intestinales y vesicales, masticación y deglución, problemas de pensamiento o razonamiento, movimiento o movilidad, habla y lenguaje. Se usan diferentes medidas de terapia, desde ultrasonido, uso de radiofrecuencia hasta masajes terapéuticos. Se trata de la primera opción terapéutica ofrecida a nuestros pacientes por su baja tasa de complicaciones. ¡Animate a recibir tus sesiones de terapia física con nosotros!",
    },
    {
      id: 11,
      src: IMG11,
      alt: "Masajes terapeuticos",
      texto:
        "El masaje relajante consiste en la realización de maniobras superficiales en las que la intensidad de la presión es suave y el ritmo lento y reiterativo, de manera que al recibir un contacto repetido y constante, se pierde la sensación de dolor y los músculos se relajan.",
      titulo_Tratamiento_1: "Masaje relajante",
      titulo_Tratamiento_2: "Sueco",
      texto_2:
        "El masaje sueco es una técnica de relajación que ayuda a liberar la tensión existente en el cuerpo. Esta terapia manual se puede complementar con aromaterapia y de igual modo con musicoterapia",
      titulo_Tratamiento_3: "Deportivo",
      texto_3:
        "El masaje deportivo es una técnica de fisioterapia dirigida de forma específica al deportista y que tiene como objetivo mejorar su rendimiento deportivo, cuidar su cuerpo y acelerar su recuperación tras las lesiones y también evitarlas",
    },
    {
      id: 12,
      src: IMG12,
      alt: "Osteopatia",
      texto:
        "En esta se emplea la manipulación física para aumentar la movilidad de las articulaciones, aliviar la tensión muscular y mejorar el suministro de sangre a los tejidos. El objetivo del tratamiento es mejorar la salud general de todos los sistemas del cuerpo mediante la manipulación y el fortalecimiento de la estructura musculoesquelética. Está orientada al restablecimiento de disfunciones en los músculos y el esqueleto y de la postura, poniendo el foco en la columna vertebral y traumatismos. Se basa en el principio de que la función y la estructura tienen una relación directa, de manera que si la estructura no tiene equilibrio, la función estará afectada.",
      titulo_Tratamiento_1: "Osteopatía",
      titulo_Tratamiento_2: "Viscosuplementación",
      texto_2:
        "Es la aplicación intraarticular percutánea de una sustancia visco-elastica considerada una excelente alternativa terapéutica que tiene el objetivo de disminuir el dolor articular, y mejorar el estado funcional de la articulación artrósica. \nBeneficios: Segura, resultados inmediatos, bajo costo.",
      titulo_Tratamiento_3: "Terapia biológica",
      texto_3:
        "Es la aplicación de tejido autólogo, fuente de factores de crecimiento y células progenitoras, en el área de la lesión, con el fin de concebir la reparación y formación de nuevo tejido.",
    },
    {
      id: 13,
      src: IMG13,
      alt: "Fisioterapia",
      texto:
        "La fisioterapia manual es la especialidad de la fisioterapia en el ámbito de la ortopedia, que clásicamente se ha definido como el arte y la ciencia del tratamiento de las condiciones neuro-músculo-esqueléticas disfuncionales del ser humano, mediante manipulaciones manuales musculares y articulares analíticas basadas en el estudio biomecánico de las mismas, incluidas las de alta velocidad y corto rango de movimiento.",
      titulo_Tratamiento_1: "Terapia manual",
      titulo_Tratamiento_2: "Vendaje neuromuscular",
      texto_2:
        "El vendaje neuromuscular o kinesiotaping consiste en cintas de algodón con un adhesivo acrílico que se utilizan con la intención de tratar lesiones de atletas y otros trastornos físicos.",
      titulo_Tratamiento_3: "Terapia analgesica",
      texto_3:
        "Un analgésico es cualquier miembro del grupo de medicamentos utilizados para lograr la analgesia, el alivio y reducción del dolor, ya sea de cabeza, muscular o en general.",
      titulo_Tratamiento_4: "Neuromodulación",
      texto_4:
        "Los neuromoduladores son sustancias endógenas, productos del metabolismo, que sin ser acumuladas y liberadas por terminales nerviosas actúan presinápticamente, modulando la síntesis y/o liberación de un neurotransmisor.",
      titulo_Tratamiento_5: "Ejercicio terapeutico",
      texto_5:
        "Se llama ejercicio físico a cualquier actividad física que mejora y mantiene la actitud física, la salud y el bienestar de la persona.",
      titulo_Tratamiento_6: "Terapia descongestiva compleja",
      texto_6:
        "Llevan a cabo varios acercamientos terapéuticos físicos para producir los resultados más óptimos y lograr la reducción más pronta del linfedema, obteniendo resultados aún mejores.",
      titulo_Tratamiento_7: "Electroterapia",
      texto_7:
        "La electroterapia es una técnica que se engloba dentro de la medicina física y rehabilitación y se define como el arte y la ciencia del tratamiento de lesiones y enfermedades por medio de la electricidad.",
      titulo_Tratamiento_8: "Punción seca",
      texto_8:
        "La punción seca es una técnica invasiva utilizada en fisioterapia. Está indicada principalmente para el tratamiento de puntos gatillo miofasciales (que explicamos exactamente qué es más adelante), aunque también, en algunos casos, para otros dolores musculares.",
    },
    {
      id: 14,
      src: IMG14,
      alt: "Rehabilitacion",
      texto:
        "Se trata de una medida de terapia física y rehabilitación donde se hace uso de una máquina de ultrasonido, la cual es muy útil para personas que presentan lesiones como contracturas musculares, y algunas lesiones.Por no presentar efectos adversos, es excelente para el tratamiento no invasivo en personas quienes presentan lesiones musculares, además ayuda a mejorar la circulación en las zonas donde este sea aplicado con algunas excepciones.",
      titulo_Tratamiento_1: "Ultrasonido terapeutico",
    },
  ];
  return (
    <>
      <div className="uk-container uk-padding-large">
        <div class="uk-padding-large uk-margin-left">
          <div>
            <h1 class=" uk-text-center">
              <span style={{ color: "#ff6912" }}> Nuestros Tratamientos</span>
            </h1>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-lead uk-text-medium  "
              style={{ color: "#173864", fontWeight: 500 }}
            >
              {" "}
              Trabajamos para eliminar el dolor y recuperar la calidad de vida
              de nuestros pacientes{" "}
            </span>
          </div>
        </div>

        <div class=" uk-text-center uk-hidden@m" uk-grid>
          {Images.map((image) => (
            <div class="uk-card uk-card-default">
              <div class="uk-card-media-top">
                <img src={image.src} alt={image.alt} className="uk-padding" />
              </div>
              <div class="uk-card-body">
                <div class="uk-inline">
                  <button
                    class="uk-button uk-button-default"
                    type="button"
                    style={{
                      border: "0px solid #000000",
                    }}
                  >
                    <h3 class="uk-card-title uk-text-center">
                      <span style={{ color: "#ff6912" }}>
                        {image.titulo_Tratamiento_1}
                      </span>
                    </h3>
                  </button>
                  <div uk-dropdown="mode: click">
                    <p> {image.texto}</p>
                  </div>
                  <button
                    class="uk-button uk-button-default"
                    type="button"
                    style={{
                      border: "0px solid #000000",
                      marginTop: 20,
                    }}
                  >
                    <h3 class="uk-card-title uk-text-center">
                      <span style={{ color: "#ff6912" }}>
                        {image.titulo_Tratamiento_2}
                      </span>
                    </h3>
                  </button>
                  <div uk-dropdown="mode: click">
                    <p> {image.texto_2}</p>
                  </div>
                  <button
                    class="uk-button uk-button-default"
                    style={{
                      border: "0px solid #000000",
                      marginTop: 20,
                    }}
                    type="button"
                  >
                    <h3 class="uk-card-title uk-text-center">
                      <span style={{ color: "#ff6912" }}>
                        {image.titulo_Tratamiento_3}
                      </span>
                    </h3>
                  </button>
                  <div uk-dropdown="mode: click">
                    <p> {image.texto_3}</p>
                  </div>
                  {image.alt === "Fisioterapia" ? (
                    <div>
                      <button
                        class="uk-button uk-button-default"
                        style={{
                          border: "0px solid #000000",
                          marginTop: 20,
                        }}
                        type="button"
                      >
                        <h3 class="uk-card-title uk-text-center">
                          <span style={{ color: "#ff6912" }}>
                            {image.titulo_Tratamiento_4}
                          </span>
                        </h3>
                      </button>
                      <div uk-dropdown="mode: click">
                        <p> {image.texto_4}</p>
                      </div>
                      <button
                        class="uk-button uk-button-default"
                        style={{
                          border: "0px solid #000000",
                          marginTop: 20,
                        }}
                        type="button"
                      >
                        <h3 class="uk-card-title uk-text-center">
                          <span style={{ color: "#ff6912" }}>
                            {image.titulo_Tratamiento_5}
                          </span>
                        </h3>
                      </button>
                      <div uk-dropdown="mode: click">
                        <p> {image.texto_5}</p>
                      </div>
                      <button
                        class="uk-button uk-button-default"
                        style={{
                          border: "0px solid #000000",
                          marginTop: 20,
                        }}
                        type="button"
                      >
                        <h3 class="uk-card-title uk-text-center">
                          <span style={{ color: "#ff6912" }}>
                            {image.titulo_Tratamiento_6}
                          </span>
                        </h3>
                      </button>
                      <div uk-dropdown="mode: click">
                        <p> {image.texto_6}</p>
                      </div>
                      <button
                        class="uk-button uk-button-default"
                        style={{
                          border: "0px solid #000000",
                          marginTop: 20,
                        }}
                        type="button"
                      >
                        <h3 class="uk-card-title uk-text-center">
                          <span style={{ color: "#ff6912" }}>
                            {image.titulo_Tratamiento_7}
                          </span>
                        </h3>
                      </button>
                      <div uk-dropdown="mode: click">
                        <p> {image.texto_7}</p>
                      </div>
                      <button
                        class="uk-button uk-button-default"
                        style={{
                          border: "0px solid #000000",
                          marginTop: 20,
                        }}
                        type="button"
                      >
                        <h3 class="uk-card-title uk-text-center">
                          <span style={{ color: "#ff6912" }}>
                            {image.titulo_Tratamiento_8}
                          </span>
                        </h3>
                      </button>
                      <div uk-dropdown="mode: click">
                        <p> {image.texto_8}</p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          class="uk-child-width-1-2@s  uk-visible@m uk-flex uk-flex-center"
          uk-grid={"true"}
        >
          {Images.map((image) => (
            <>
              <div class="uk-card uk-card-default uk-text-center">
                <div class="uk-card-media-top">
                  <img src={image.src} alt={image.alt} className="uk-padding" />
                </div>
                <div class="uk-card-body">
                  <div class="uk-inline">
                    <button
                      class="uk-button uk-button-default"
                      type="button"
                      style={{
                        border: "0px solid #000000",
                        marginTop: 20,
                        width: 500,
                      }}
                    >
                      <h3 class="uk-card-title uk-text-center">
                        <span style={{ color: "#ff6912" }}>
                          {image.titulo_Tratamiento_1}
                        </span>
                      </h3>
                    </button>
                    <div uk-dropdown="mode: click">
                      <p> {image.texto}</p>
                    </div>
                    <button
                      class="uk-button uk-button-default"
                      type="button"
                      style={{
                        border: "0px solid #000000",
                        marginTop: 20,
                        width: 500,
                      }}
                    >
                      <h3 class="uk-card-title uk-text-center">
                        <span style={{ color: "#ff6912" }}>
                          {image.titulo_Tratamiento_2}
                        </span>
                      </h3>
                    </button>
                    <div uk-dropdown="mode: click">
                      <p> {image.texto_2}</p>
                    </div>
                    <button
                      class="uk-button uk-button-default"
                      style={{
                        border: "0px solid #000000",
                        marginTop: 20,
                        width: 500,
                      }}
                      type="button"
                    >
                      <h3 class="uk-card-title uk-text-center">
                        <span style={{ color: "#ff6912" }}>
                          {image.titulo_Tratamiento_3}
                        </span>
                      </h3>
                    </button>
                    <div uk-dropdown="mode: click">
                      <p> {image.texto_3}</p>
                    </div>
                    {image.alt === "Fisioterapia" ? (
                      <div>
                        <button
                          class="uk-button uk-button-default"
                          style={{
                            border: "0px solid #000000",
                            marginTop: 20,
                            width: 500,
                          }}
                          type="button"
                        >
                          <h3 class="uk-card-title uk-text-center">
                            <span style={{ color: "#ff6912" }}>
                              {image.titulo_Tratamiento_4}
                            </span>
                          </h3>
                        </button>
                        <div uk-dropdown="mode: click">
                          <p> {image.texto_4}</p>
                        </div>
                        <button
                          class="uk-button uk-button-default"
                          style={{
                            border: "0px solid #000000",
                            marginTop: 20,
                            width: 500,
                          }}
                          type="button"
                        >
                          <h3 class="uk-card-title uk-text-center">
                            <span style={{ color: "#ff6912" }}>
                              {image.titulo_Tratamiento_5}
                            </span>
                          </h3>
                        </button>
                        <div uk-dropdown="mode: click">
                          <p> {image.texto_5}</p>
                        </div>
                        <button
                          class="uk-button uk-button-default"
                          style={{
                            border: "0px solid #000000",
                            marginTop: 20,
                            width: 500,
                          }}
                          type="button"
                        >
                          <h3 class="uk-card-title uk-text-center">
                            <span style={{ color: "#ff6912" }}>
                              {image.titulo_Tratamiento_6}
                            </span>
                          </h3>
                        </button>
                        <div uk-dropdown="mode: click">
                          <p> {image.texto_6}</p>
                        </div>
                        <button
                          class="uk-button uk-button-default"
                          style={{
                            border: "0px solid #000000",
                            marginTop: 20,
                            width: 500,
                          }}
                          type="button"
                        >
                          <h3 class="uk-card-title uk-text-center">
                            <span style={{ color: "#ff6912" }}>
                              {image.titulo_Tratamiento_7}
                            </span>
                          </h3>
                        </button>
                        <div uk-dropdown="mode: click">
                          <p> {image.texto_7}</p>
                        </div>
                        <button
                          class="uk-button uk-button-default"
                          style={{
                            border: "0px solid #000000",
                            marginTop: 20,
                            width: 500,
                          }}
                          type="button"
                        >
                          <h3 class="uk-card-title uk-text-center">
                            <span style={{ color: "#ff6912" }}>
                              {image.titulo_Tratamiento_8}
                            </span>
                          </h3>
                        </button>
                        <div uk-dropdown="mode: click">
                          <p> {image.texto_8}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>

        <div class="uk-padding-large uk-margin-left">
          <div>
            <h1 class=" uk-text-center">
              <span style={{ color: "#ff6912" }}>
                {" "}
                No Olvides Seguirnos en Facebook{" "}
              </span>
            </h1>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-light uk-text-small  "
              style={{ color: "#173864" }}
            >
              {" "}
              Aqui encontraras diferentes tipos de videos acerca de nuestros
              tratamientos{" "}
            </span>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-padding-large">
          <iframe
            title="Dolor de rodilla"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDoctorsTotalClinic%2Fvideos%2F374177077390453%2F&show_text=true&width=560&t=0"
            width="560"
            height="429"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen="true"
          ></iframe>
        </div>

        <div className="uk-flex uk-flex-center uk-padding-large">
          <iframe
            title="Dolor de espalda"
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDoctorsTotalClinic%2Fvideos%2F784528485511793%2F&show_text=true&width=560&t=0"
            width="560"
            height="429"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen="true"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default TratamientosMedia;
