import Cover from "../images/cover.jpg";

const TratamientoPotada = () => {
  return (
    <>
      <div
        className="uk-container uk-width-1-1 uk-padding-large uk-visible@m"
        style={{ backgroundColor: "rgb(245,244,244)" }}
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-width-1-3@m ">
            <img src={Cover} alt="" />
          </div>
          <div class="uk-card uk-card-default uk-card-body uk-margin-left  uk-container-xsmall uk-text-break">
            <p
              className=" uk-text-center uk-text-large"
              style={{ color: "#ff6912" }}
            >
              {" "}
              Acerca de Nuestros Tratamientos{" "}
            </p>
            En Doctors Total Clinic contamos con diferentes tratamientos y
            servicios para el cuidado y bienestar del paciente.
            <br />
            Nuestros tratamientos son muy efectivos y se adaptan a cada
            paciente. Nos encargamos de siempre brindar todo lo necesario para
            que el paciente reciba una atención médica de calidad. <br />
            Aquí mismo manejamos tanto la consulta médica como el tratamiento, y
            de ser necessario, brindamos la terapia física y todo lo necesario
            con la rehabilitación de nuestro paciente.
          </div>
        </div>
      </div>

      {/* aqui comienza la version movil */}
      <div className="uk-flex-middle uk-hidden@m">
        <div
          className="uk-width-1-3@m uk-flex-first uk-card-body"
          uk-scrollspy="cls:uk-animation-fade"
        >
          <img alt="" src={Cover} />
        </div>
        <div
          className=" uk-margin-medium-left uk-text-left  "
          uk-scrollspy="cls:uk-animation-slide-right"
        >
          <div className=" uk-container-xsmall uk-text-break uk-width-2-3@m uk-margin-top">
            <p
              className=" uk-text-center uk-text-large"
              style={{ color: "#ff6912" }}
            >
              {" "}
              Acerca de Nuestros Tratamientos{" "}
            </p>
            <p>
              En Doctors Total Clinic manejamos una amplia gama de tratamientos
              y servicios para el cuidado del paciente. Nuestros tratamientos
              son muy efectivos y se adaptan a las necesidades de cada paciente.
              Nos encargamos de siempre brindar todo lo necesario para que el
              paciente reciba una atención médica de calidad. Aqui mismo
              manejamos tanto la consulta médica como el tratamiento, y de ser
              necesario, brindamos la terapia fisica y todo lo necesario con la
              rehabilitación de nuestro paciente.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TratamientoPotada;
