import React, { useState, useEffect } from "react";
import InicioAcerca from "../components/inicio-acerca";
import InicioTratamiento from "../components/inicio-tratamientos";
import InicioTestimonios from "../components/inicio-testimonios";
import InicioMapa from "../components/inicio-mapa";
import CookieConsent from "react-cookie-consent";
import { Button } from "react-bootstrap";
import Politicas from "./politicas";
import InicioAsociaciones from "../components/InicioAsociaciones";
import InicioPlantillas from "../components/InicioPlantillas";

const Inicio = () => {
  const [politicas, setPoliticas] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <InicioAcerca />
      <InicioTratamiento />
      <InicioPlantillas />
      <InicioTestimonios />
      <InicioAsociaciones />
      <InicioMapa />
      <CookieConsent
        enableDeclineButton={true}
        declineButtonText="No acepto"
        contentClasses="uk-text-center"
        buttonText="Acepto"
        cookieName="cookieConsent"
        buttonStyle={{ backgroundColor: "rgb(255,105,18)", color: "white" }}
        declineButtonStyle={{ backgroundColor: "red", marginTop: "15%" }}
        location="bottom"
        style={{
          width: "100%",
          height: "100%",
          background: "rgba(52, 52, 52, 0.8)",
          fontSize: "25px",
        }}
        contentStyle={{
          height: "10%",
          width: "100%",
          alignContent: "center",
        }}
        containerClasses="uk-flex uk-flex-center"
      >
        Para el correcto funcionamiento de nuestra web, es necesario que aceptes
        nuestro{" "}
        <Button onClick={() => setPoliticas(!politicas)}>
          Aviso de privacidad.
        </Button>
        {politicas ? <Politicas /> : null}
      </CookieConsent>
    </>
  );
};

export default Inicio;
