import React from "react";
import ALIANZ from "../images/alianz.png";
import GNP from "../images/gnp.png";
import PREVEM from "../images/prevem.png";
import SEGUROSATLAS from "../images/SEGUROS.png";
import SEGUROSBX from "../images/segurosbx.png";
import SEGUROSMONT from "../images/segurosMont.png";
import SURA from "../images/Sura.png";

const InicioAsociaciones = () => {
  return (
    <>
      <div className="uk-margin-large-bottom uk-visible@m">
        <div class="uk-padding ">
          <div>
            <h1
              class=" uk-text-center uk-margin-large-bottom"
              style={{ fontSize: 50 }}
            >
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                Aseguradoras
              </span>
            </h1>
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ objectFit: "contain", width: 300, height: 300 }}
              src={SEGUROSMONT}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ objectFit: "contain", width: 300, height: 300 }}
              src={ALIANZ}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={PREVEM}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ objectFit: "contain", width: 300, height: 300 }}
              src={GNP}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ objectFit: "contain", width: 300, height: 300 }}
              src={SEGUROSBX}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={SEGUROSATLAS}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div
            className="uk-width-1-6"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={SURA}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
        </div>
      </div>
      <div className="uk-margin-large-bottom uk-hidden@m">
        <div class="uk-padding ">
          <div>
            <h1
              class=" uk-text-center uk-margin-large-bottom"
              style={{ fontSize: 50 }}
            >
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                Aseguradoras
              </span>
            </h1>
          </div>
        </div>
        <div className="uk-flex uk-flex-column uk-flex-center">
          <div className="uk-width-1-1  uk-padding uk-margin-medium-bottom">
            <img
              style={{ width: "100%", height: "100%" }}
              src={SEGUROSMONT}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={ALIANZ}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={PREVEM}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={GNP}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={SEGUROSBX}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={SEGUROSATLAS}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
          <div className="uk-width-1-1 uk-padding uk-margin-medium-bottom ">
            <img
              style={{ width: "100%", height: "100%" }}
              src={SURA}
              alt="Consejo mexicano de ortopedia y traumatologia"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InicioAsociaciones;
