import * as React from "react";

const ContactoContacto = () => {
  return (
    <>
      <div class="uk-padding-large uk-margin-left">
        <div>
          <h1 class=" uk-text-center">
            <span style={{ color: "#ff6912" }}> Siguenos en Facebook </span>
          </h1>
        </div>
        <div className="uk-text-center">
          <span
            className="uk-text-light uk-text-small  "
            style={{ color: "#173864", fontWeight: 500 }}
          >
            Para nosotros es importante saber lo que nuestros pacientes dicen de
            nosotros y de nuestros servicios.
          </span>
        </div>
      </div>
      <div className="uk-flex uk-flex-center">
        <iframe
          title="Facebook"
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDoctorsTotalClinic%2Fvideos%2F374177077390453%2F&show_text=true&width=560&t=0"
          style={{
            border: "none",
            overflow: "hidden",
            width: 560,
            height: 429,
          }}
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
};

export default ContactoContacto;
