import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./pages/inicio";
import Nosotros from "./pages/nosotros.js";
import Header from "./components/header";
import Footer from "./components/footer";
import Contacto from "./pages/contacto";
import Tratamientos from "./pages/tratamientos";
import Politicas from "./pages/politicas";
import Fab from "@mui/material/Fab";
import { AiOutlineWhatsApp } from "react-icons/ai";
import API from "./pages/API";
import APE from "./pages/APE";
import APP from "./pages/APP";
import Convenios from "./pages/Convenios";
import Productos from "./pages/Productos";
import ProductosShowroom from "./pages/ProductosShowroom";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />

        <a
          target="_blank"
          rel="noreferrer"
          className="uk-link-muted uk-text-decoration-none uk-link-heading uk-text-default"
          href="https://api.whatsapp.com/send/?phone=5217446010021&text=Buen+Dia+Me+Gustaria+Agendar+Una+Cita%3A&app_absent=0"
        >
          <Fab
            uk-tooltip="title:WhatsApp; pos: right"
            variant="extended"
            color="primary"
            aria-label="add"
            style={{
              position: "fixed",
              top: "84%",
              left: 20,
              backgroundColor: "#25D366",
              PointerEvents: "none",
            }}
          >
            <AiOutlineWhatsApp size={34} />
          </Fab>
        </a>

        <Routes>
          <Route exact path="/" element={<Inicio />} />
          <Route exact path="/nosotros" element={<Nosotros />} />
          <Route exact path="/contacto" element={<Contacto />} />
          <Route exact path="/tratamientos" element={<Tratamientos />} />
          <Route exact path="/politicas" element={<Politicas />} />
          <Route exact path="/API" element={<API />} />
          <Route exact path="/APE" element={<APE />} />
          <Route exact path="/APP" element={<APP />} />
          <Route exact path="/aseguradoras" element={<Convenios />} />
          <Route exact path="/plantillas" element={<Productos />} />
          <Route exact path="/plantillas/:id" element={<ProductosShowroom />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};
export default App;
