import React from "react";
import ALIANZ from "../images/alianz.png";
import GNP from "../images/gnp.png";
import PREVEM from "../images/prevem.png";
import SEGUROSATLAS from "../images/SEGUROS.png";
import SEGUROSBX from "../images/segurosbx.png";
import SEGUROSMONT from "../images/segurosMont.png";
import cover from "../images/handshake.jpg";

const Convenios = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Convenios = [
    {
      id: 1,
      name: "ALIANZ",
      img: ALIANZ,
    },
    {
      id: 2,
      name: "GNP",
      img: GNP,
    },
    {
      id: 3,
      name: "PREVEM",
      img: PREVEM,
    },

    {
      id: 5,
      name: "SEGUROS ATLAS",
      img: SEGUROSATLAS,
    },
    {
      id: 6,
      name: "SEGUROS BX",
      img: SEGUROSBX,
    },
    {
      id: 7,
      name: "SEGUROS MONT",
      img: SEGUROSMONT,
    },
  ];
  return (
    <>
      <div className="uk-padding uk-visible@m">
        <img
          src={cover}
          alt="saludo de mano"
          style={{ width: "100%", height: "500px", objectFit: "cover" }}
        />
      </div>
      <div className=" uk-container-medium uk-visible@m">
        <div className="uk-padding ">
          <div>
            <h1 className=" uk-text-center uk-margin-large-bottom">
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                Aseguradoras en Convenio con Doctors Total Clinic
              </span>
            </h1>
          </div>
        </div>
        <div
          className=" uk-text-center uk-width-1-1  uk-flex uk-flex-center uk-margin-large-left uk-margin-large-right "
          uk-grid={"true"}
        >
          {Convenios.map((convenio) => (
            <div
              className=" uk-text-center uk-width-1-4 uk-flex-center"
              key={convenio.id}
            >
              <div
                className=" uk-align-center uk-flex "
                style={{
                  borderStyle: "none",
                  verticalAlign: "top",
                  maxWidth: "100%",
                  height: "auto",
                }}
              >
                <img
                  src={convenio.img}
                  alt={convenio.name}
                  style={{
                    width: "320px",
                    aspectRatio: "auto 320 / 202",
                    height: "202px",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="uk-padding uk-hidden@m">
        <img
          src={cover}
          alt="saludo de mano"
          style={{ width: "100%", height: "200px", objectFit: "cover" }}
        />
      </div>

      <div className=" uk-container-medium uk-hidden@m">
        <div className="uk-padding ">
          <div>
            <h1 className=" uk-text-center uk-margin-large-bottom">
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                Aseguradoras en Convenio con Doctors Total Clinic
              </span>
            </h1>
          </div>
        </div>
        <div className=" uk-text-center uk-width-1-1 uk-hidden@m uk-flex uk-flex-column">
          {Convenios.map((convenio) => (
            <div className="uk-padding" key={convenio.id}>
              <div className="uk-width-1-1">
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={convenio.img}
                  alt={convenio.name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Convenios;
