import { Link } from "react-router-dom";
import Equipo from "../images/equipo2.jpg";
import videoPrincipal from "../videos/menuPrincipal.mp4";
const InicioAcerca = () => {
  return (
    <>
      <div className="uk-margin-xlarge-bottom" id="top">
        <img
          alt=""
          src={Equipo}
          style={{ height: "100%", width: "100%" }}
        ></img>

        <div class="uk-padding-large uk-margin-left">
          <div>
            <h1 class=" uk-text-center">
              Acerca de
              <span className="uk-text " style={{ color: "#ff6912" }}>
                {" "}
                Doctors Total Clinic
              </span>
            </h1>
          </div>
          <div
            className="uk-flex uk-flex-row container"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
              marginTop: "50px",
            }}
          >
            <video class="video-fluid z-depth-1" loop controls muted>
              <source src={videoPrincipal} type="video/mp4" />
            </video>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-lead uk-text-medium"
              style={{ color: "#173864", fontWeight: 500 }}
            >
              {" "}
              Trabajamos para eliminar el dolor y recuperar la calidad de vida
              de nuestros pacientes{" "}
            </span>
          </div>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center  uk-visible@m">
          <div
            className=" uk-text-center "
            uk-scrollspy="cls:uk-animation-slide-right"
          >
            <div className=" uk-container-xsmall uk-text-break ">
              <p>
                Somos una Clínica especializada en el tratamiento del dolor de
                rodillas, cadera y espalda. Contamos con un equipo de expertos
                especializados en diferentes áreas de la salud, para brindar una
                atención multidisciplinaria e integral, ademas estamos en la
                vanguardia de la tecnología para brindar un servicio de calidad.
              </p>
              <div className="uk-flex uk-flex-center">
                <Link
                  to="/nosotros"
                  class="uk-button uk-button-secondary active-button "
                  style={{ borderRadius: 20 }}
                >
                  V<span className="uk-text-lowercase">er más</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* version movil */}
      <div className="uk-flex-middle uk-hidden@m">
        <div
          className=" uk-margin-medium-left uk-text-left  "
          uk-scrollspy="cls:uk-animation-slide-right"
        >
          <div className=" uk-container-xsmall uk-text-break uk-width-2-3@m ">
            <p>
              Somos una Clínica especializada en el tratamiento del dolor de
              rodillas, cadera y espalda. Contamos con un equipo de expertos
              especializados en diferentes áreas de la salud, para brindar una
              atención multidisciplinaria e integral, ademas estamos en la
              vanguardia de la tecnología para brindar un servicio de calidad.
            </p>

            <div className="uk-flex uk-flex-center uk-margin-top uk-margin-large-right">
              <Link
                to={`/nosotros`}
                class="uk-button uk-button-secondary active-button"
                style={{ borderRadius: 20 }}
              >
                V<span className="uk-text-lowercase">er más</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InicioAcerca;
