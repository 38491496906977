import * as React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/png.png";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { IoLogoTiktok } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <div
        className="uk-margin-xlarge-top uk-visible@m"
        style={{ backgroundColor: "black" }}
      >
        <div className="uk-flex uk-flex-left uk-margin-right uk-margin-xlarge-left">
          <div className="uk-width-1-6 ">
            <img src={Logo} alt="logo" />
          </div>
          <div className="uk-flex uk-flex-center uk-margin-xlarge-left uk-margin-large-top">
            <div className="uk-margin-xlarge-right uk-text-normal uk-text-large">
              <div className="uk-flex uk-flex-column">
                <Link to="/" className="uk-link-muted uk-text-decoration-none">
                  {" "}
                  Inicio
                </Link>
                <Link
                  to="/nosotros"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  Nosotros
                </Link>
                <Link
                  to="/aseguradoras"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  Aseguradoras
                </Link>
              </div>
            </div>
            <div className="uk-margin-xlarge-right uk-text-normal uk-text-large">
              <div className="uk-flex uk-flex-column">
                <Link
                  to="/contacto"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  Contacto
                </Link>
                <Link
                  to="/tratamientos"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  Tratamientos
                </Link>
                <Link
                  to="/plantillas"
                  className="uk-link-muted uk-text-decoration-none"
                >
                  {" "}
                  Plantillas
                </Link>
              </div>
            </div>

            <div className="uk-margin-xlarge-right uk-text-normal uk-text-large ">
              <div className="uk-flex uk-flex-column">
                <span style={{ color: "#ffffff", fontWeight: "700" }}>
                  {" "}
                  Abierto de 9:00 AM a 6:00 PM
                </span>
              </div>
              <span style={{ width: 230, height: 2 }}>
                <a
                  className="uk-text-decoration-none"
                  style={{ color: "#FF6912" }}
                  href="tel:+527446010021"
                >
                  {" "}
                  Llama al: 744 601 0021
                </a>
              </span>
            </div>

            <div className="uk-flex uk-flex-column">
              {" "}
              <span style={{ color: "#8B9999" }}>
                {" "}
                © 2022 DoctorsTotalClinic
              </span>
              <Link
                to="/API"
                className="uk-link-muted uk-text-decoration-none uk-margin-top"
              >
                Aviso de Privacidad
              </Link>
              <Link
                to="/APE"
                className="uk-link-muted uk-text-decoration-none uk-margin-top"
              >
                Aviso de Privacidad para empleados
              </Link>
              <Link
                to="/APP"
                className="uk-link-muted uk-text-decoration-none uk-margin-top"
              >
                Aviso de Privacidad de proveedores
              </Link>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr style={{ width: "80%" }} className="uk-divider-icon"></hr>
        </div>

        <div className="uk-flex-center uk-text-normal uk-text-large uk-padding ">
          <span
            className="uk-flex uk-flex-center"
            style={{ color: "#ffffff", fontWeight: "700" }}
          >
            Siguenos!
          </span>
          <div className="uk-flex uk-flex-center uk-margin">
            <a
              href="https://www.facebook.com/DoctorsTotalClinic"
              className="uk-link-muted uk-text-decoration-none uk-margin-right"
            >
              {" "}
              <AiOutlineFacebook size={39} />
            </a>
            <a
              href="https://www.instagram.com/doctorstotalclinic/"
              className="uk-link-muted uk-text-decoration-none uk-margin-right"
            >
              {" "}
              <AiOutlineInstagram size={39} />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=5217441884422&text=Buen+Dia+Me+Gustaria+Agendar+Una+Cita%3A&app_absent=0"
              className="uk-link-muted uk-text-decoration-none uk-margin-right"
            >
              {" "}
              <AiOutlineWhatsApp size={39} />
            </a>

            <a
              href="https://www.tiktok.com/@doctorstotalclinic"
              className="uk-link-muted uk-text-decoration-none uk-margin-right"
            >
              {" "}
              <IoLogoTiktok size={39} />
            </a>
          </div>
          <div>
            <span className="uk-flex uk-flex-center" style={{ fontSize: 13 }}>
              Made by: WaterBerryDev
            </span>
          </div>
        </div>
      </div>

      {/* Aqui comienza el footer de movil*/}

      <div className="uk-hidden@m" style={{ backgroundColor: "black" }}>
        <div className="uk-flex uk-flex-center ">
          <Link
            to="/"
            className="uk-link-muted uk-text-decoration-none uk-margin-top"
          >
            {" "}
            Inicio
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/nosotros"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Nosotros
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/tratamientos"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Tratamientos
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/padecimientos"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Padecimientos
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/contacto"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Contacto
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/aseguradoras"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Aseguradoras
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <Link
            to="/plantillas"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Plantillas
          </Link>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center">
          <a
            href="tel:+527446010021"
            className="uk-link-muted uk-text-decoration-none"
          >
            {" "}
            Llama al: 744 601 0021
          </a>
        </div>
        <div className="uk-flex uk-flex-center">
          <hr className="uk-divider-small"></hr>
        </div>
        <div className="uk-flex uk-flex-center uk-padding">
          <a
            href="https://www.facebook.com/DoctorsTotalClinic"
            className="uk-link-muted uk-text-decoration-none uk-margin-left"
          >
            {" "}
            <AiOutlineFacebook size={34} />
          </a>
          <a
            href="https://www.instagram.com/doctors_totalclinic/?hl=es-la"
            className="uk-link-muted uk-text-decoration-none uk-margin-left"
          >
            {" "}
            <AiOutlineInstagram size={34} />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=5217441884422&text=Buen+Dia+Me+Gustaria+Agendar+Una+Cita%3A&app_absent=0"
            className="uk-link-muted uk-text-decoration-none uk-margin-left"
          >
            {" "}
            <AiOutlineWhatsApp size={34} />
          </a>
        </div>
        <div className="uk-flex uk-flex-center">
          <div className="uk-flex uk-flex-column  uk-text-center">
            {" "}
            <Link to="/API" className="uk-link-muted uk-text-decoration-none">
              Aviso de privacidad
            </Link>
            <hr className="uk-divider-small"></hr>
            <Link to="/APE" className="uk-link-muted uk-text-decoration-none">
              Aviso de privacidad para empleados
            </Link>
            <hr className="uk-divider-small"></hr>
            <Link to="/APP" className="uk-link-muted uk-text-decoration-none">
              Aviso de privacidad de proveedores
            </Link>
          </div>
        </div>

        <div
          className="uk-flex uk-flex-center uk-padding"
          style={{ color: "#8B9999" }}
        >
          {" "}
          © 2022 DoctorsTotalClinic
        </div>
      </div>
    </>
  );
};

export default Footer;
