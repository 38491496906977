import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "../files/AP_Corto.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Politicas = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  return (
    <div
      className="uk-padding pdf-container"
      style={{ width: "100%", height: "100%" }}
    >
      <div className="uk-flex uk-flex-center">
        <button
          className="uk-button uk-button-default uk-text-default"
          onClick={prevPage}
          disabled={pageNumber === 1}
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Anterior
        </button>
        <button
          className=" uk-margin-large-left uk-button uk-button-default uk-text-default"
          onClick={nextPage}
          disabled={pageNumber === numPages}
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Siguiente
        </button>
      </div>

      <Document
        file={PDF}
        onContextMenu={(e) => e.preventDefault()}
        onLoadSuccess={onDocumentLoadSuccess}
        className=" uk-flex uk-flex-center"
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};
export default Politicas;
