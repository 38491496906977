import { useEffect } from "react";
import ContactoContacto from "../components/contacto-contacto";
import InicioContacto from "../components/inicio-contacto";
import InicioMapa from "../components/inicio-mapa";
const Contacto = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <InicioContacto />
      <InicioMapa />
      <ContactoContacto />
    </div>
  );
};

export default Contacto;
