import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Plantilla_1 from "../videos/Plantilla_1.mp4";
import Plantilla_2 from "../videos/Plantilla_2.mp4";
import Plantilla_3 from "../videos/Plantilla_3.mp4";
import Plantilla_4 from "../videos/Plantilla_4.mp4";
import Plantilla_5 from "../videos/Plantilla_5.mp4";
import Plantilla_6 from "../videos/Plantilla_6.mp4";
import Plantilla_7 from "../videos/Plantilla_7.mp4";
import Plantilla_8 from "../videos/Plantilla_8.mp4";
import confort from "../images/confort.png";
import elite from "../images/elite.png";
import estandar from "../images/estandar.png";
import kids from "../images/kids.png";
import magnetica from "../images/magnetica.png";
import ortopedica from "../images/ortopedica.png";
import sport from "../images/sport.png";
import ultra from "../images/ultra.png";
import { IoArrowBackOutline } from "react-icons/io5";

const Plantillas = [
  {
    id: 1,
    name: "EVA SPORT",
    video: Plantilla_1,
    img: sport,
  },
  {
    id: 2,
    name: "ULTRA SUAVE",
    video: Plantilla_2,
    img: ultra,
  },
  {
    id: 3,
    name: "KIDS",
    video: Plantilla_3,
    img: kids,
  },
  {
    id: 4,
    name: "ELITE",
    video: Plantilla_4,
    img: elite,
  },
  {
    id: 5,
    name: "CONFORT",
    video: Plantilla_5,
    img: confort,
  },
  {
    id: 6,
    name: "ORTOPEDICA",
    video: Plantilla_6,
    img: ortopedica,
  },
  {
    id: 7,
    name: "STANDARD",
    video: Plantilla_7,
    img: estandar,
  },
  {
    id: 8,
    name: "BIO-MAGNÉTICA",
    video: Plantilla_8,
    img: magnetica,
  },
];

const ProductosShowroom = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  let Plantilla = find(parseInt(id));

  return (
    <>
      <button
        className="uk-button uk-margin-large-left uk-margin-small-top"
        onClick={() => navigate("/productos")}
        style={{ backgroundColor: "#fff", color: "#000" }}
      >
        <IoArrowBackOutline size={60} />
      </button>
      <div className="uk-text-center">
        <h1 class="uk-margin-bottom">
          <span style={{ color: "#ff6912" }}>{Plantilla.name}</span>
        </h1>
      </div>
      <div className="uk-visible@m">
        <div className=" uk-flex uk-flex-row container ">
          <video class="video-fluid z-depth-1" loop controls muted>
            <source src={Plantilla.video} type="video/mp4" />
          </video>

          <img src={Plantilla.img} alt={Plantilla.name} />
        </div>
      </div>
      <div className="uk-hidden@m">
        <video class="video-fluid z-depth-1" loop controls muted>
          <source src={Plantilla.video} type="video/mp4" />
        </video>
        <img class="uk-padding" src={Plantilla.img} alt={Plantilla.name} />
      </div>
    </>
  );
};

export default ProductosShowroom;

function find(id) {
  return Plantillas.find((p) => p.id === id);
}
