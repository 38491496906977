const InicioMapa = () => {
  return (
    <>
      <div class="uk-padding-large uk-margin-left ">
        <div>
          <h1 class=" uk-text-center">
            <span style={{ color: "#ff6912" }}> Ubicación </span>
          </h1>
        </div>
        <div className="uk-text-center">
          <span
            className="uk-text-lead uk-text-medium"
            style={{ color: "#173864", fontWeight: 500 }}
          >
            Actualmente contamos con instalaciones en Acapulco, Guerrero.
          </span>
        </div>
      </div>
      <div class="uk-flex uk-flex-center">
        <iframe
          title="Ubicacion"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.23113322264!2d-99.88122638470537!3d16.864457122113553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ca576083ee158d%3A0xdfb2e9ba8a113973!2sDoctors%20Total%20clinic!5e0!3m2!1ses-419!2smx!4v1638925632764!5m2!1ses-419!2smx"
          style={{ width: 600, height: 600, border: 0 }}
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

export default InicioMapa;
