import * as React from "react";
import Equipo2 from "../images/equipo2.jpg";

const NosotrosNosotros = () => {
  return (
    <>
      <div class="uk-padding-large uk-margin-left">
        <div>
          <h1 class=" uk-text-center">
            <span style={{ color: "#ff6912" }}> Nosotros </span>
          </h1>
        </div>
        <div className="uk-text-center"></div>
      </div>
      <div className="uk-flex uk-flex-middle uk-text-center uk-margin-xlarge-left uk-visible@s">
        <div
          className=" uk-margin-xlarge-left uk-text-left uk-width-1-2 "
          uk-scrollspy="cls:uk-animation-slide-right"
        >
          <div className=" uk-container-xsmall uk-text-break ">
            <p>
              Somos una Clínica especializada en el tratamiento del dolor de
              rodillas, cadera y espalda. Contamos con un equipo de expertos
              especializados en diferentes áreas de la salud, para brindar una
              atención multidisciplinaria e integral, ademas estamos en la
              vanguardia de la tecnología para brindar un servicio de calidad.
            </p>
          </div>
        </div>
        <div
          className="uk-flex uk-card-body uk-widh"
          uk-scrollspy="cls:uk-animation-fade"
        >
          <img alt="" src={Equipo2} width="500"></img>
        </div>
      </div>

      {/* Aqui comienza la version movil */}

      <div className="uk-flex-middle uk-hidden@s">
        <div
          className="uk-width-1-3@m uk-flex-first uk-card-body"
          uk-scrollspy="cls:uk-animation-fade"
        >
          <img alt="" src={Equipo2} width="500"></img>
        </div>
        <div
          className=" uk-margin-medium-left uk-text-left  "
          uk-scrollspy="cls:uk-animation-slide-right"
        >
          <div className=" uk-container-xsmall uk-text-break uk-width-2-3@m ">
            <p>
              Somos una Clínica especializada en el tratamiento del dolor de
              rodillas, cadera y espalda. Contamos con un equipo de expertos
              especializados en diferentes áreas de la salud, para brindar una
              atención multidisciplinaria e integral, ademas estamos en la
              vanguardia de la tecnología para brindar un servicio de calidad.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NosotrosNosotros;
