import * as React from "react";
import { Link } from "react-router-dom";
import IMG1 from "../images/1.png";
import IMG2 from "../images/2.png";
import IMG3 from "../images/3.png";
import IMG4 from "../images/4.png";
import IMG5 from "../images/5.png";
import IMG6 from "../images/6.png";
import IMG7 from "../images/7.png";
import IMG8 from "../images/8.png";
import IMG9 from "../images/9.png";
import IMG10 from "../images/10.png";
import IMG11 from "../images/11.png";
import IMG12 from "../images/12.png";
import IMG13 from "../images/13.png";
import IMG14 from "../images/14.png";

const InicioTratamiento = () => {
  const Images = [
    {
      id: 1,
      src: IMG1,
      alt: "Trtamiento para el dolor de rodilla sin cirugia",
    },
    {
      id: 2,
      src: IMG2,
      alt: "Trtamiento sin cirugia para el dolor de espalda",
    },
    { id: 3, src: IMG3, alt: "Trtamiento para el dolor de cadera" },
    {
      id: 4,
      src: IMG4,
      alt: "Tratamiento para el dolor de hombro sin cirugia",
    },
    { id: 5, src: IMG5, alt: "Protesis de rodilla" },
    { id: 6, src: IMG6, alt: "Protesis de cadera" },
    { id: 7, src: IMG7, alt: "Protesis de disco cervical" },
    { id: 8, src: IMG8, alt: "Protesis de disco lumbar" },
    { id: 9, src: IMG9, alt: "Artroscopia" },
    { id: 10, src: IMG10, alt: "Trtamientos para lesiones en deportistas" },
    { id: 11, src: IMG11, alt: "Masajes terapeuticos" },
    { id: 12, src: IMG12, alt: "Osteopatia" },
    { id: 13, src: IMG13, alt: "Fisioterapia" },
    { id: 14, src: IMG14, alt: "Rehabilitacion" },
  ];
  return (
    <>
      <div className="uk-margin-xlarge-bottom">
        <div class="uk-padding-large uk-margin-left ">
          <div>
            <h1 class=" uk-text-center">
              <span style={{ color: "#ff6912" }}>
                {" "}
                Tratamientos Fundamentales
              </span>
            </h1>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-lead uk-text-medium"
              style={{ color: "#173864", fontWeight: 500 }}
            >
              En Doctors Total Clinic nos esforzamos por ofrecerte una atención
              personalizada, con una calidad y un servicio de primera clase.
            </span>
          </div>
        </div>
        <div className="uk-visible@m">
          <div class="uk-flex uk-flex-center ">
            <div
              class="uk-position-relative uk-visible-toggle uk-light"
              tabindex="-1"
              uk-slider="center: true; autoplay: true; autoplay-interval: 2000"
            >
              <ul class="uk-slider-items uk-grid">
                {Images.map((image) => (
                  <li class="uk-width-3-5" key={image.id}>
                    <div class="uk-panel">
                      <img src={image.src} alt={image.alt} />
                      <div class="uk-position-center uk-panel"></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="uk-flex uk-flex-center uk-margin-top">
            <Link
              to="/tratamientos"
              class="uk-button uk-button-secondary active-button "
              style={{ borderRadius: 20 }}
            >
              V<span className="uk-text-lowercase">er más</span>
            </Link>
          </div>
        </div>
      </div>

      {/* version movil */}
      <div className="uk-hidden@m">
        <div class="uk-flex uk-flex-column uk-text-center ">
          <div
            class="uk-position-relative uk-visible-toggle uk-light"
            tabindex="-1"
            uk-slider="center: true; autoplay: true; autoplay-interval: 2000"
          >
            <ul class="uk-slider-items uk-grid">
              {Images.map((image) => (
                <li class="uk-width-5-5" key={image.id}>
                  <div class="uk-panel">
                    <img src={image.src} alt={image.alt} />
                    <div class="uk-position-center uk-panel"></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-top">
          <Link
            to="/tratamientos"
            class="uk-button uk-button-secondary active-button "
            style={{ borderRadius: 20 }}
          >
            V<span className="uk-text-lowercase">er más</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InicioTratamiento;
