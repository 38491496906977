import React from "react";
import Magnetica from "../images/plantillas-showroom/2.png";
import Suave from "../images/plantillas-showroom/3.png";
import Ortopedica from "../images/plantillas-showroom/4.png";
import Sport from "../images/plantillas-showroom/5.png";
import Kids from "../images/plantillas-showroom/6.png";
import Standard from "../images/plantillas-showroom/7.png";
import Confort from "../images/plantillas-showroom/8.png";
import Elite from "../images/plantillas-showroom/9.png";

import { Link, useNavigate } from "react-router-dom";

const InicioPlantillas = () => {
  const navigate = useNavigate();
  const Images = [
    {
      id: 1,
      src: Magnetica,
      alt: "Plantilla ortopedica magnetica",
      path: "plantillas/8",
    },
    {
      id: 2,
      src: Suave,
      alt: "Plantilla ortopedica suave",
      path: "plantillas/2",
    },
    {
      id: 3,
      src: Ortopedica,
      alt: "Plantilla ortopedica",
      path: "plantillas/6",
    },
    {
      id: 4,
      src: Sport,
      alt: "Plantilla ortopedica deportiva",
      path: "plantillas/1",
    },
    {
      id: 5,
      src: Kids,
      alt: "Plantilla ortipedica para niños",
      path: "plantillas/3",
    },
    { id: 6, src: Standard, alt: "Plantilla ortopedica", path: "plantillas/7" },
    {
      id: 7,
      src: Confort,
      alt: "Plantilla ortopedica standard",
      path: "plantillas/5",
    },
    { id: 8, src: Elite, alt: "Plantilla ortopedica ", path: "plantillas/4" },
  ];
  return (
    <>
      <div className="uk-margin-xlarge-bottom">
        <div class="uk-padding-large uk-margin-left ">
          <div>
            <h1 class=" uk-text-center">
              <span style={{ color: "#ff6912" }}>
                {" "}
                Tratamientos Fundamentales
              </span>
            </h1>
          </div>
          <div className="uk-text-center">
            <span
              className="uk-text-lead uk-text-medium"
              style={{ color: "#173864", fontWeight: 500 }}
            >
              En Doctors Total Clinic nos esforzamos por ofrecerte una atención
              personalizada, con una calidad y un servicio de primera clase.
            </span>
          </div>
        </div>
        <div className="uk-visible@m">
          <div class="uk-flex uk-flex-center ">
            <div
              class="uk-position-relative uk-visible-toggle uk-light"
              tabindex="-1"
              uk-slider="center: true; autoplay: true; autoplay-interval: 2000"
            >
              <ul class="uk-slider-items uk-grid">
                {Images.map((image) => (
                  <li
                    class="uk-width-3-5"
                    key={image.id}
                    onClick={() => navigate(image.path)}
                  >
                    <div class="uk-panel">
                      <img src={image.src} alt={image.alt} />
                      <div class="uk-position-center uk-panel"></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="uk-flex uk-flex-center uk-margin-top">
            <Link
              to="/plantillas"
              class="uk-button uk-button-secondary active-button "
              style={{ borderRadius: 20 }}
            >
              V<span className="uk-text-lowercase">er más</span>
            </Link>
          </div>
        </div>
      </div>

      {/* version movil */}
      <div className="uk-hidden@m">
        <div class="uk-flex uk-flex-column uk-text-center ">
          <div
            class="uk-position-relative uk-visible-toggle uk-light"
            tabindex="-1"
            uk-slider="center: true; autoplay: true; autoplay-interval: 2000"
          >
            <ul class="uk-slider-items uk-grid">
              {Images.map((image) => (
                <li class="uk-width-5-5" key={image.id}>
                  <div class="uk-panel">
                    <img src={image.src} alt={image.alt} />
                    <div class="uk-position-center uk-panel"></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-top">
          <Link
            to="/plantillas"
            class="uk-button uk-button-secondary active-button "
            style={{ borderRadius: 20 }}
          >
            V<span className="uk-text-lowercase">er más</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default InicioPlantillas;
