import { useState } from "react";
import Consultorio from "../images/consultorio.jpg";
import { send } from "emailjs-com";
import { Link } from "react-router-dom";

const InicioContacto = () => {
  const [disable, setDisable] = useState(true);

  const [toSend, setToSend] = useState({
    from_name: "",
    email_contacto: "",
    numero_contacto: "",
    mensaje: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      "service_6u84ymx",
      "template_7p8iywt",
      toSend,
      "user_yn4Fyyj5hj7gH2E8m0oi8"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
    window.location.replace(
      "https://api.whatsapp.com/send/?phone=5217441884422&text=Buen+Dia+Mi+Nomnbre+Es+" +
        toSend.from_name +
        "+Mi+Numero+Es%20" +
        toSend.numero_contacto +
        "+Mi+Email+Es%20" +
        toSend.email_contacto +
        "+Mi+Mensaje+Es%20" +
        toSend.mensaje +
        "%3A&app_absent=0"
    );
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="uk-cover-container" style={{ height: 900 }}>
      <img
        alt=""
        src={Consultorio}
        style={{ height: "100%", width: "100%" }}
      ></img>
      <div class="uk-overlay-primary uk-position-cover"></div>

      <div className="uk-overlay uk-light uk-position-top-center">
        <div className="uk-animation-slide-top">
          <h1>
            Contáctanos <br />
          </h1>
        </div>
      </div>

      <div
        className="uk-overlay uk-overlay-default uk-position-center "
        style={{ borderRadius: 25 }}
      >
        <div className="">
          <form>
            <fieldset class="uk-fieldset">
              <legend class="uk-legend uk-text-center">
                Agenda tu cita en linea
              </legend>

              <div class="uk-margin">
                Nombre
                <input
                  class="uk-input"
                  type="text"
                  name="from_name"
                  onChange={handleChange}
                  value={toSend.from_name}
                />
              </div>
              <div class="uk-margin">
                Correo Electronico
                <input
                  class="uk-input"
                  type="text"
                  name="email_contacto"
                  onChange={handleChange}
                  value={toSend.email_contacto}
                />
              </div>
              <div class="uk-margin">
                Numero de Telefono
                <input
                  class="uk-input"
                  type="text"
                  name="numero_contacto"
                  onChange={handleChange}
                  value={toSend.numero_contacto}
                />
              </div>

              <div class="uk-margin">
                Padecimientos
                <textarea
                  class="uk-textarea"
                  rows="5"
                  name="mensaje"
                  onChange={handleChange}
                  value={toSend.message}
                ></textarea>
              </div>
              <div class="uk-margin">
                Acepto{" "}
                <Link to="/politicas">
                  Aviso de privacidad y Terminos y Condiciones
                </Link>
                <input
                  class="uk-checkbox uk-margin-left"
                  type="checkbox"
                  name="terminos_condiciones"
                  style={{ backgroundColor: "black" }}
                  onClick={() => setDisable(!disable)}
                />
              </div>
              <button
                type="submit"
                class="uk-button uk-button-secondary "
                style={{ borderRadius: 20, marginLeft: 120 }}
                onClick={onSubmit}
                disabled={disable}
              >
                Enviar
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InicioContacto;
