import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "../files/APE/APECorto.pdf";
import Acceso from "../files/APE/Acceso.pdf";
import Cancelacion from "../files/APE/Cancelacion.pdf";
import Rectificacion from "../files/APE/Rectificacion.pdf";
import Oposicion from "../files/APE/Oposicion.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const APE = () => {
 
    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  return (
    <div className=" pdf-container" style={{ width: "100%", height: "100%" }}>
      <div className="uk-flex uk-flex-center uk-margin-xlarge-top">
        <button
          className="uk-button uk-button-default uk-text-default"
          onClick={prevPage}
          disabled={pageNumber === 1}
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Anterior
        </button>
        <button
          className=" uk-margin-large-left uk-button uk-button-default uk-text-default"
          onClick={nextPage}
          disabled={pageNumber === numPages}
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Siguiente
        </button>
      </div>
      <div className="uk-flex uk-flex-center uk-margin-medium-top uk-visible@m">
        <a
          href={Acceso}
          download="Formato de Acceso.pdf"
          target="_blank"
          rel="noreferrer"
          className="uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Acceso
        </a>
        <a
          href={Cancelacion}
          download="Formato de Cancelacion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Cancelacion
        </a>
        <a
          href={Rectificacion}
          download="Formato de Rectificacion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Rectificacion
        </a>
        <a
          href={Oposicion}
          download="Formato de Oposicion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Oposicion
        </a>
      </div>

      <Document
        file={PDF}
        onContextMenu={(e) => e.preventDefault()}
        onLoadSuccess={onDocumentLoadSuccess}
        className=" uk-flex uk-flex-center uk-visible@m"
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <Document
        file={PDF}
        onContextMenu={(e) => e.preventDefault()}
        onLoadSuccess={onDocumentLoadSuccess}
        className="uk-hidden@m"
        style={{ fontSize: "10px" }}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="uk-flex uk-flex-center uk-hidden@m">
        <a
          href={Acceso}
          download="Formato de Acceso.pdf"
          target="_blank"
          rel="noreferrer"
          className="uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Acceso
        </a>
        <a
          href={Cancelacion}
          download="Formato de Cancelacion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Cancelacion
        </a>
      </div>
      <div className="uk-flex uk-flex-center uk-hidden@m ">
        <a
          href={Rectificacion}
          download="Formato de Rectificacion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Rectificacion
        </a>
        <a
          href={Oposicion}
          download="Formato de Oposicion.pdf"
          target="_blank"
          rel="noreferrer"
          className=" uk-margin-small-left uk-button uk-button-default uk-text-default"
          style={{ backgroundColor: "white", margin: 10 }}
        >
          Oposicion
        </a>
      </div>
    </div>
  )
}

export default APE